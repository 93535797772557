import { Colors } from '@/styles/colors';
import { MediaQuery, mq } from '@/styles/mediaQueries';
import { Spacings } from '@/styles/spacings';
import {
  FontLineHeight,
  FontSize,
  FontWeight,
} from '@/styles/tokens';
import { css } from '@emotion/css';

export const mobileMenuBtn = css({
  display: 'none',
  // overflow: 'hidden',
  [mq(MediaQuery.TabletVertical)]: {
    display: 'block',
  },
});

export const navigationMobile = css({
  display: 'flex',
  flexDirection: 'column',
  marginTop: Spacings.S24,
  '> button': {
    marginBottom: Spacings.S12,
  },
});

export const linksMobile = css({
  display: 'flex',
  flexDirection: 'column',
  listStyle: 'none',
  margin: 0,
  padding: 0,
  paddingTop: Spacings.S24,
  borderTop: `1px solid ${Colors.gray200}`,
  li: {
    fontSize: FontSize.S24,
    fontWeight: FontWeight.S500,
    paddingBottom: 32,
    paddingTop: 8,
    '&:last-child': {
      paddingBottom: 16,
    },
    [mq(MediaQuery.Mobile)]: {
      fontSize: FontSize.S20,
      paddingBottom: 28,
    },
  },
});

export const mobileMenuOverlay = css({
  height: 'calc(100vh - 80px)',
  transition: 'ease .2s',
  transform: 'translateX(0)',
});

export const mobileMenu = css({
  position: 'fixed',
  background: 'rgba(0, 0, 0, 0.5)',
  width: '100%',
  zIndex: 99,
  height: '100vh',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  transition: 'ease .2s',
  transform: 'translateX(0)',
});

export const mobileMenuClosed = css({
  transform: 'translateX(100%)',
  visibility: 'hidden',
});

export const mobileMenuHeader = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 0 32px',
  [mq(MediaQuery.Mobile)]: {
    padding: '0 0 24px',
  },
});

export const mobileMenuContent = css({
  position: 'fixed',
  background: Colors.gray100,
  width: '100%',
  maxWidth: '100%',
  height: '100vh',
  right: 0,
  left: 0,
  top: 0,
  opacity: 1,
  padding: 32,
  overflow: 'auto',
  boxSizing: 'border-box',
  [mq(MediaQuery.Mobile)]: {
    padding: 24,
  },
  '> p': {
    fontSize: FontSize.S14,
    color: Colors.gray800,
    fontWeight: FontWeight.S500,
    marginTop: Spacings.S16,
    lineHeight: FontLineHeight.H24,
  },
});
