import {
  MobileMenuBurger,
  MobileMenuClose,
} from '@/components/Icons/MobileMenuBurger';
import { Colors } from '@/styles/colors';
import { cx } from '@emotion/css';
import { useState } from 'react';

import { AnchorLink } from '@/components/AnchorLink/AnchorLink';

import { FontSize } from '@/styles/tokens';

import { LogoNerdbord } from '@/components/Icons/LogoNerdbord';
import * as styles from './MobileMenu.styles';

interface MobileMenuProps {
  menuItems: { name: string; href: string }[];
}

export const MobileMenu = (props: MobileMenuProps) => {
  const [isOpen, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!isOpen);
  };

  return (
    <div className={styles.mobileMenuBtn}>
      {isOpen ? (
        <MobileMenuClose onClick={handleClick} />
      ) : (
        <MobileMenuBurger onClick={handleClick} />
      )}
      <div
        className={cx(
          styles.mobileMenu,
          !isOpen && styles.mobileMenuClosed
        )}
      >
        <div className={styles.mobileMenuContent}>
          <div className={styles.mobileMenuHeader}>
            <LogoNerdbord />
            <MobileMenuClose onClick={handleClick} />
          </div>
          <nav className={styles.navigationMobile}>
            <ul className={styles.linksMobile}>
              {props.menuItems.map(({ name, href }) => (
                <li key={name}>
                  <AnchorLink
                    size={FontSize.S20}
                    color={Colors.gray800}
                    url={href}
                  >
                    {name}
                  </AnchorLink>
                </li>
              ))}
            </ul>
            {/*<ul className={styles.linksMobile}>*/}
            {/*  {navigatePaths.map(({ name, href }) => (*/}
            {/*    <li key={name}>*/}
            {/*      <AnchorLink*/}
            {/*        size={FontSize.S16}*/}
            {/*        color={Colors.gray800}*/}
            {/*        url={href}*/}
            {/*      >*/}
            {/*        {name}*/}
            {/*      </AnchorLink>*/}
            {/*    </li>*/}
            {/*  ))}*/}
            {/*</ul>*/}
            {/*<Button*/}
            {/*  size={ButtonSize.medium}*/}
            {/*  variant={ButtonVariant.secondary}*/}
            {/*  icon={<ArrowRightTop color={Colors.white} />}*/}
            {/*>*/}
            {/*  Book a demo*/}
            {/*</Button>*/}
            {/*<Button size={ButtonSize.medium} icon={<ArrowRight />}>*/}
            {/*  Try Nerdbord for free*/}
            {/*</Button>*/}
          </nav>
        </div>
      </div>
    </div>
  );
};
